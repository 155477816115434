@media only screen and (max-width: 945px) {

    header .navbar {
        position: fixed;
        top: -100vh;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: white;
        z-index: 100;
        transition: 1s;
    }

    header .show-nav {
        transform: translateY(100vh);
    }
}
