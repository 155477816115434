.container-content {
  overflow-x: auto;
  display: flex;
  width: 100%;
  scroll-behavior: smooth;
  margin: 22px 0;
  padding: 10px 0px;
  gap: 15px;
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}



.container-content {
  overflow: hidden;
}

.container-content .testimonial-items {
  display: flex;
  width: max-content;
  gap: 20px;
  animation: scroll 30s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - 15px));
  }
}

.container-content:hover .testimonial-items {
  animation-play-state: paused;
}