.logos {
    overflow: hidden;
    margin-top: 13px;
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.reviews{
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

@keyframes slide {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-200px * 6))}
}

@keyframes slidereverse {
    0% { transform: translateX(calc(-200px * 6)); }
    100% { transform: translateX(0)}
    
}

.logo-track {
    animation: 20s slide  infinite linear;
    width: calc(200px * 12);
}

.logo-track1 {
    animation: 20s slidereverse  infinite linear;
    width: calc(200px * 12);
}

.logo-track1 img{
    height: 100px;
    object-fit: contain;
}

.logo-track1:hover {
    animation-play-state: paused;
}

.logo-track img{
    height: 100px;
    object-fit: contain;
}

.logos:hover .logo-track {
    animation-play-state: paused;
}