.specalitycard {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
    background-color: #1651c6;
    border-radius: 9px;

}

.specalitycontent-wrapper {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: #1651c6;
    color: white;
    border-radius: 9px;
    padding: 4px;
    position: relative;
    height: 72px;
    padding: 10px;
}


/* .specalitycontent-wrapper p {
    text-align: center;
    font-size: 23px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
} */

.img-wrapper{
    width: 100%;
}

.img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.specalitycontent-wrapper img {
    width: 70px;
    height: 70px;
}

.specalitycontent-wrapper a {
    position: absolute;
    bottom: -35px;
}


@media screen and (max-width: 1024px) {
    .specalitycard {
        width: 45%;
    }
}

@media screen and (max-width: 504px) {
    .specalitycard {
        width: 100%;
    }
}