.testimonial {
    height: 300px;
    width: 300px;
    flex-shrink: 0;
    margin-right: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    border: 1px solid #1651C6;
    border-radius: 20px;
    justify-content: space-between;
}


/* .testimonial p,
h3 {
    text-align: center;
    margin: 10px 0;
}

.testimonial h3 {
    color: #1651C6;
} */