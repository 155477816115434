.Company-Portfolio {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
  }
  
  .Company-Portfolio img {
    width: 45%;
  }
  
  .Portfolio-Content {
    width: 45%;
  }
  
  
  .portfolio-item {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  
  .item-content img {
    width: 60px;
    height: 60px;
  }
  
  .item-content {
    display: flex;
    align-items: center;
    gap: 21px;
    width: 630px;
    height: 70px;
    border: 2px solid #c8d6f4;
    border-radius: 50px 50px 0px 50px;
    padding: 5px;
  
  }
  
  .Portfolio-Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px
  }
  
  @media screen and (max-width: 1024px) {
    .item-content img {
      width: 50px;
      height: 50px;
    }
  
    .item-content {
      width: 500px;
      height: 60px;
      padding: 5px;
    }
  }
  
  
  @media screen and (max-width: 874px) {
    .Portfolio-img {
      display: none;
    }
  
    .Portfolio-Content {
      width: 100%;
    }
  
  
  }


  .speciality-cards {
    margin: 40px 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  }
  
  