.SearchBar{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #ffffff;
    width: 70%;
    position: relative;
    font-size: 13px;
    bottom:55px;
    border-radius: 23px 23px 0 23px;
    border: 1px solid #a8bce6;
    padding: 20px;
    flex-direction: column;
}



.Search-content {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    width: 90%;
}

.searchfield {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 40%;
    justify-content: space-between;
    position: relative;
}

.searchfield p {
    /* color: #aeaeae; */
    font-weight: bold;
}

.searchfield select {
    width: 90%;
    padding: 8px 2px;
    border: none;
    border-bottom: 2px solid #ccc;
    background-color: #fff;
    color: #275eca;
    line-height: 1.5;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    /* font-size: 15px; */
}

.searchfield select option {
    background-color: #fff;
    /* Background color */
    color: #333;
    /* Text color */
    padding: 10px;
}

.searchfield input {
    box-sizing: border-box;
    margin: 0;
    border: none;
    border-bottom: 2px solid #c6c6c6;
    padding: 8px 2px;
    /* font-size: 16px;
    font-weight: 400; */
    width: 100%;
    color: #275eca;
    background-color: transparent;
    box-shadow: none;
    font-weight: bold;
    border-radius: 0;
    resize: none;
    outline: none;
}

.Search-content button {
    width: 95px;
    padding: 10px 20px;
    border: none;
    border-radius: 14px 14px 0 14px;
    background-color: #1651c6;
    color: #e2e9f8;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    margin-left: 13px;
}

.Searchbar input[type="text"]::placeholder {
    color: #8da9e3;
    font-weight: bold;
}

.Searchbar input[type="text"]::placeholder {
    color: #8da9e3;
    font-weight: bold;
}


@media screen and (max-width: 768px) {
    .SearchBar {
        width: 100%;
        position: unset;
        border-radius: 0;
        border: none;
        align-items: center;
    }

    .searchfield {
        width: 100%;
    }

    .searchfield select {
        width: 100%;
    }
    .Search-content {
        flex-direction: column;
        gap: 20px;
    }

    .Search-content button {
        width: 100%;
    }
}