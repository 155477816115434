.doctorcard {
    display: flex;
    width: 47%;
    border: 1px solid #ccd9f5;
    align-content: center;
    align-items: start;
    justify-content: flex-start;
    gap: 35px;
}


.doctorcontent-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
}


@media screen and (max-width: 1322px) {
    .doctorcard {
        width: 100%;
    }
}






