@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

body{
    margin: 0;
    font-family: "Roboto", "Ubuntu", "Rubik", sans-serif;
    font-size: 1rem;
}


select {
  appearance: none;
  -webkit-appearance: none;
}

.suggestions::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.suggestions {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

input:focus, select:focus,textarea {
  outline: none;     
}

.App{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}